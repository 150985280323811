import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import AccentLine from '../components/AccentLine';

import theme from '../styles/theme/theme';

import downArrow from '../../static/media/down-arrow-circle.svg';

const MastHead = styled.section`
	padding-top: 3rem;
	padding-bottom: 0rem;

	@media (min-width: 768px) {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}

	h1 {
		font-size: 3rem;
		line-height: 1.3;

		@media (min-width: 768px) {
			font-size: 5rem;
			line-height: 1.5;
		}
	}

	.grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 60px;

		@media (max-width: 999px) {
			grid-template-columns: 1fr;
			grid-template-rows: 1fr;
			row-gap: 0;
		}
	}

	.text-wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		padding: 3rem 0;
		height: 100%;
	}
`;

const ColumnsSection = styled.section`
	background-color: ${({ theme }) => theme.colors.lightBgGrey};
	padding-top: 4rem;
	padding-bottom: 8rem;
	position: relative;

	@media (min-width: 768px) {
		padding-top: 6rem;
		padding-bottom: 6rem;
	}

	h2 {
		font-size: 2rem;
		text-align: center;
	}

	.grid-columns {
		display: grid;
		grid-template-columns: 1fr;
		grid-column-gap: 4rem;
		grid-row-gap: 5rem;
		justify-content: center;

		@media (min-width: 768px) {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	.column-items {
		margin-top: 3.5rem;

		@media (min-width: 768px) {
			margin-top: 5.5rem;
		}

		li {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;

			img {
				flex: 1 1 auto;
				margin-bottom: 2.5rem;
				max-height: 80px;

				@media (min-width: 768px) {
					margin-bottom: 3rem;
				}
			}

			p {
				font-family: ${({ theme }) => theme.fonts.secondary};
				font-weight: ${({ theme }) => theme.fonts.weights.semiBold};
				font-size: 2.2rem;
				max-width: 12em;
				margin: 0 auto;
			}
		}
	}

	.down-arrow-wrapper {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 50%);

		img {
			max-width: 84px;
		}
	}
`;

export const CashbackPromoPageTemplate = ({
	pageHeading,
	pageIllustration,
	introHeading,
	introColumns,
}) => (
	<article>
		<MastHead className="section intro-section" color={theme.colors.tertiary}>
			<div className="container">
				<div className="grid two-columns">
					<div className="grid-column">
						<div>
							<Img fluid={pageIllustration.childImageSharp.fluid} />
						</div>
					</div>
					<div className="grid-column">
						<div className="text-wrapper">
							<h1>{pageHeading}</h1>
						</div>
					</div>
				</div>
			</div>
		</MastHead>
		<ColumnsSection className="section">
			<div className="container">
				<h2>{introHeading}</h2>
				<AccentLine align={'center'} color={theme.colors.primary} />
				<ul className="grid-columns column-items">
					{introColumns.map((item, i) => (
						<li key={`${item.text}-${i}`}>
							<img src={item.icon.publicURL} />
							<p>{item.text}</p>
						</li>
					))}
				</ul>
			</div>
			<div className="down-arrow-wrapper">
				<img src={downArrow} alt="Get in touch with us through form below." />
			</div>
		</ColumnsSection>
	</article>
);

CashbackPromoPageTemplate.propTypes = {
	title: PropTypes.string,
};

const CashbackPromoPage = ({ data }) => {
	const { frontmatter } = data.markdownRemark;

	return (
		<Layout page={'promo'}>
			<SEO
				title={frontmatter.title}
				description="Finding a home that suits your needs can be a challenge. Connect with Parker and the team to discuss your wishlist and let our realtors find your dream home."
			/>
			<CashbackPromoPageTemplate
				pageHeading={frontmatter.pageHeading}
				pageIllustration={frontmatter.pageIllustration}
				introHeading={frontmatter.introHeading}
				introColumns={frontmatter.introColumns}
			/>
		</Layout>
	);
};

CashbackPromoPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default CashbackPromoPage;

export const cashbackPromoPageQuery = graphql`
	query CashbackPromoPage($id: String!) {
		markdownRemark(id: { eq: $id }) {
			frontmatter {
				title
				pageHeading
				pageIllustration {
					childImageSharp {
						fluid(maxWidth: 1920) {
							...GatsbyImageSharpFluid
						}
					}
				}
				introHeading
				introColumns {
					text
					icon {
						publicURL
					}
				}
			}
		}
	}
`;
